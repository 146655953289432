import Vue from 'vue'
import './plugins/axios'
import App from './App.vue'
import router from './router'

import './style/common.css'
import jquery from 'jquery'
import request from './utils/request'

import socketIo from 'vue-socket.io'
import io from 'socket.io-client'
import { getToken } from "@/utils/auth";
import { CountDown, NoticeBar,Swipe, SwipeItem  } from "vant";

Vue.prototype.$http = request
Vue.config.productionTip = false

window.$ = jquery
window.jQuery = jquery;
Vue.mixin({
    data: () => {
        return {
            globalData: {
                roomId: '',
                XPLATFORM: ''
            }
        }
    },
    created() {
        this.getParams()
        // this.getRoomInfo();
        // this.platFormFlag();
    },
    // updated() {
    //     this.getRoomInfo();
    //     this.platFormFlag()
    // },
    watch: {
        $route: {
            handler(val, oldval) {
                if (val) {
                    this.getParams()
                }
            },
            deep: true,
            immediate: true
        }
    },
    methods: {
        close() {
            // if (window.flutter_inappwebview) {
            //     window.flutter_inappwebview.callHandler('close')
            // }
            if (window.flutter_inappwebview) {
                window.flutter_inappwebview.callHandler('close')
            } else {
                window.parent.postMessage({
                    handler: "close"
                }, '*')
            }
        },
        getParams() {
            let arr = window.location.search.split('&');
            this.globalData.XPLATFORM = arr[1].split('=')[1];
            this.globalData.roomId = arr[2].split('=')[1];
        }
        // getRoomInfo() {
        //     const that = this
        //     if (window.flutter_inappwebview) {
        //         window.flutter_inappwebview
        //             .callHandler("getRoomInfo", "这里是传给flutter的参数")
        //             .then(function (res) {
        //                 that.globalData.roomId = res
        //             });
        //     }
        // },
        // platFormFlag() {
        //     const that = this
        //     if (window.flutter_inappwebview) {
        //         window.flutter_inappwebview
        //             .callHandler("platFormFlag", "这里是传给flutter的参数")
        //             .then(function (res) {
        //                 that.globalData.XPLATFORM = res
        //             });
        //     }
        // }
    }
})
Vue.use(new socketIo({
    debug: false,
    // 这里的端口号要和你之前在websocket.js里配置的端口号相同
    connection:'wss://zb.newtravel.cc/',
    // connection:'wss://testapi.newtravel.cc/',
    options:{
        transports: ['websocket'],
        query: {
          token: getToken(),
        },
        autoConnect: true,
        path: '/'
      }
      })
  );

Vue.use(CountDown).use(NoticeBar).use(Swipe).use(SwipeItem)
new Vue({
    router,
    render: h => h(App)
}).$mount('#app')
